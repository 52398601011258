

import BodyWrapper from "../../components/layouts/BodyWrapper";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Course } from "../../api";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        Course.getCourses().then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.meesage}).then(() => navigate("/"))
            }
            setCourses(res.courses);
        }).catch(() => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/"))
        })
    })

    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header d-flex justify-content-between">
                    <h1>Courses</h1>
                    <Link to={"/add-course"} className="btn btn-primary">Add Course</Link>
                </div>

                <div class="section-body">
                    
                    <div class="row">
                        <div class="col-12">

                            <div class="card">
                                <div class="card-header">
                                    <h4>Courses</h4>
                                    <div class="card-header-form">
                                        <form>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Search"/>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table table-striped v_center">
                                            <tr>
                                                <th>Course title</th>
                                                <th>Course Description</th>
                                                <th>Course Tecnologies</th>
                                                <th>Date added</th>
                                                <th>Action</th>
                                            </tr>
                                            {
                                                courses.length > 0 && courses.map(course => {
                                                    return (
                                                        <tr>
                                                            <td><Link to={"#"}>{course.title}</Link></td>
                                                            <td class="align-middle">
                                                            {course.description.slice(0, 100)}..
                                                            </td>
                                                            <td>{course.technologies && course.technologies.join(", ")}</td>
                                                            <td>{new Date(course.date_added).toDateString()}</td>
                                                            <td><Link to={"/edit-course/" + course.id} class="btn btn-secondary">Edit</Link></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td><Link to={"#"}>Frontend Development</Link></td>
                                                <td class="align-middle">
                                                   Gives all students need to know about building responsive user interface
                                                </td>
                                                <td>HTML, CSS, JavaScript</td>
                                                <td>2018-01-20</td>
                                                <td><a href="/schedules/123" class="btn btn-secondary">Edit</a></td>
                                            </tr> */}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const Courses = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default Courses;