

import BodyWrapper from "../../components/layouts/BodyWrapper";
import { Link } from "react-router-dom";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Lectures</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Hi, ARIB!</h2>
                    <p class="section-lead">Here's a schedule details!</p>
                    
                    <div className="row card-body">
                        {/* <div class="col-12 col-sm-6 col-md-6">
                            <img src={threadImg} width="100%" alt="" />
                        </div> */}
                        <div class="col-12 col-sm-6 col-md-6">
                            <h4>Schedule details</h4>
                            <p>Student: <Link to={"/students/123"}>Rasheed Ibraheem</Link></p>
                            <p>Schedule Date: 12/22/10022</p>
                            <br />
                            <p>Preferred Date 1: 12/22/10022</p>
                            <p>Preferred Date 2: 12/22/10022</p>
                            <p>Level: Important</p>
                            <p>Reason: Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo delectus voluptatem, natus quis, enim vel distinctio, voluptatum asperiores ab eos aliquid consequatur iusto id iste incidunt nulla! Illum, temporibus?</p>
                            <p>Preferred Medium: Phone</p>
                            <p>Category: Code not working</p>

                            <div class="card-footer text-md-right">
                                <button class="btn btn-danger" id="save-btn">Reject Schedule</button> &nbsp;
                                <button class="btn btn-primary" id="save-btn">Accept Date 1</button>&nbsp;
                                <button class="btn btn-primary" id="save-btn">Accept Date 2</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const ScheduleDetails = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default ScheduleDetails;