
import { Link } from "react-router-dom";
import threadImg from "../../assets/img/news/img12.jpg";
import BodyWrapper from "../../components/layouts/BodyWrapper";



const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Challenge 1</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Are you ready?</h2>
                    <p class="section-lead">Don't panic it's a very simple Lecture. You already have all that you need to break it.</p>
                    
                    <div class="row">
                        <div class="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Start this Challenge.</h4>
                                </div>
                                <div className="row card-body">
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <img src={threadImg} width="100%" alt="" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <h4>Submission detials</h4>
                                        <p>Submission by: Rasheed Ibraheem</p>
                                        <p>Projet: Lecture title</p>
                                        <p>Submitted: 12/2/2022 11:22:12 PM</p>

                                        <div class="card-footer text-md-right">
                                            <button class="btn btn-primary" id="save-btn"> <i className="fas fa-download"></i> Download submission file</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Project Comments</h4>
                                </div>
                                <div className="card-body">
                                    {/* <h6>Add Comment</h6> */}
                                    <form action="">
                                        <div class="form-group">
                                            <label htmlFor="comment"><h6>Project Solution Score</h6></label>
                                            <input type={"number"} id="comment"  class="form-control" placeholder="Enter your comments here" />
                                        </div>
                                        <div class="form-group">
                                            <label htmlFor="comment"><h6>Do you have any comment?</h6></label>
                                            <textarea id="comment"  class="form-control" placeholder="Enter your comments here"> </textarea>
                                        </div>
                                        <div class="card-footer text-md-right">
                                            <button class="btn btn-primary" id="save-btn"> <i className="fas fa-upload"></i> Comment and accept</button>
                                        </div>
                                        <div class="card-footer text-md-right">
                                            <button class="btn btn-danger" id="save-btn"> <i className="fas fa-upload"></i> Comment and reject</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const ChallengeSubmissionDetails = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default ChallengeSubmissionDetails;