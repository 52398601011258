
import { Link, useNavigate, useParams } from "react-router-dom";
import threadImg from "../../assets/img/news/img12.jpg";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import { useEffect, useState } from "react";
import { Lecture , serverurl} from "../../api";
import Toast from "../../components/Toast";



const Body = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [submission, setSubmission] = useState([]);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Lecture.getSubmissionDetails({id: params.id})
        .then(res => {
            if (res.type === "error") {
               return Toast.fire({icon: "error", title : res.message}).then(() => navigate("/"));
            }
            setSubmission(res.submission);
        })
        .catch(err => {
            return Toast.fire({icon: "error", title : "Network error"}).then(() => navigate("/"));
        })
    }, [])

    const gradeProject = (type) => {
        setLoading(true)
        const data = {submissionID: params.id, lectureID: submission.lecture, gradeStatus: type, note: comment};
        Lecture.gradeSubmission(data)
        .then((res) => {
            setLoading(false);
            if (res.type === "error") {
                return Toast.fire({icon: "error", title : res.message});
            }
            Toast.fire({icon: "success", title : res.message}).then(() => navigate("/lecture-submissions"));
        })
        .catch((err) => {
            setLoading(false);
            return Toast.fire({icon: "error", title : "Network error"}).then(() => navigate("/"));
        })
    }

    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>{submission && submission.title}</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Are you ready?</h2>
                    <p class="section-lead">Don't panic it's a very simple Lecture. You already have all that you need to break it.</p>
                    
                    <div class="row">
                        <div class="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Start this Lecture.</h4>
                                </div>
                                <div className="row card-body">
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <img src={threadImg} width="100%" alt="" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <h4>Submission detials</h4>
                                        <p>Submission by: {submission && submission.fname + " " + submission.lname}</p>
                                        <p>Projet: {submission && submission.title}</p>
                                        <p>Submitted: {new Date(submission.date_submitted).toDateString()} / {new Date(submission.date_submitted).toLocaleTimeString()}</p>

                                        <div class="card-footer text-md-right">
                                            <a href={serverurl + "/" + submission.file_url} download={true} target="_blank" class="btn btn-primary" id="save-btn"> <i className="fas fa-download"></i> Download submission file</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Project Comments</h4>
                                </div>
                                <div className="card-body">
                                    {/* <h6>Add Comment</h6> */}
                                    <form action="">
                                        
                                        <div class="form-group">
                                            <label htmlFor="comment"><h6>Do you have any comment?</h6></label>
                                            <textarea id="comment"  class="form-control" placeholder="Enter your comments here" onChange={(e) => setComment(e.target.value)}>{comment}</textarea>
                                        </div>
                                        <div class="card-footer text-md-right">
                                            <button type="button" class="btn btn-primary" id="save-btn" onClick={() => gradeProject("Accepted")}> <i className="fas fa-upload"></i> {loading ? "Commenting..." : "Comment and accept"}</button>
                                        </div>
                                        <div class="card-footer text-md-right">
                                            <button type="button" class="btn btn-danger" id="save-btn" onClick={() => gradeProject("Rejected")}> <i className="fas fa-upload"></i> {loading ? "Commenting..." : "Comment and reject"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const LectureSubmissionDetails = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default LectureSubmissionDetails;