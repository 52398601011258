

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Challenge, Course, Lecture } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({title: "", description: "", level: 1, technologies: [], stacks: []});
    const [files, setFiles] = useState({source_file: null, picture: null});
    const [technologies, setTechnologies] = useState([]);
    const [courses, setCourses] = useState([]);
    

    //Input change handler
    const inputChange = (e) => {
        
        if (e.target.name !== "stacks" && e.target.name !== "technologies") {
            return setForm({...form, [e.target.name]: e.target.value});
        }

        //For Checkboxes
        if(form[e.target.name].indexOf(e.target.value) > -1) {
            const values = form[e.target.name].filter(tech => tech !== e.target.value);
            setForm({...form,[e.target.name]: values});
        } else {
            const prevValue = form[e.target.name];
            prevValue.push(e.target.value);
            setForm({...form,[e.target.name]: prevValue});
        }

    }

    //File change handler
    const handleFile = e => {
        setFiles({...files, [e.target.name]: e.target.files[0]});
    }

    useEffect(() => {
        Course.getTechnologies().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setTechnologies(res.technologies);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
        Course.getCourses().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setCourses(res.courses);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
    }, [])

    
    const submitChallengeForm = e => {
        e.preventDefault();
        const challengeForm = new FormData();
        for (const [key, value] of Object.entries(form)) {
            challengeForm.append(key, value);
        }

        challengeForm.append("picture", files["picture"]);
        challengeForm.append("source_file", files["source_file"]);

        console.log(files["picture"])

        Challenge.addChallenge(challengeForm).then(res => {
            console.log(res);
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Challenge added successfully!"}).then(() => navigate("/challenges"))
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }


    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Add Challenge</h1>
                </div>

                <div className="section-body">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-7">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Add new challenge</h4>
                                </div>
                                <form action="" onSubmit={submitChallengeForm} method="POST">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Challenge Title</label>
                                            <input type="text" className="form-control" onChange={inputChange} name="title" value={form.title} />
                                        </div>
                                        <div className="form-group">
                                            <label>Level</label>
                                            <select className="form-control select2" onChange={inputChange} name="level" value={form.level}>
                                                <option value={1}>Level 1</option>
                                                <option value={2}>Level 2</option>
                                                <option value={3}>Level 3</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Challenge Description</label>
                                            <textarea 
                                            className="form-control"
                                            name="description"
                                            onChange={inputChange}
                                            value={form.description}
                                            ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Technologies</label>
                                            <div className="d-flex flex-wrap justify-content-between">
                                            {
                                                technologies.length > 0 && technologies.map(tech => {
                                                    return <div key={tech.id}>
                                                        <input 
                                                        type="checkbox" 
                                                        value={tech.name} 
                                                        name="technologies" 
                                                        onChange={inputChange}
                                                        id={tech.name}
                                                    /> <label htmlFor={tech.name}>{tech.name}</label>
                                                    </div>
                                                })
                                            }
                                            </div>
                                           
                                        </div>
                                        <div className="form-group">
                                            <label>Stacks</label>
                                            <div>
                                                {
                                                    courses.length > 0 && courses.map(course => {
                                                        return <div key={course.id}>
                                                            <input 
                                                    type="checkbox" 
                                                    value={course.title} 
                                                    name="stacks" 
                                                    onChange={inputChange}
                                                /> {course.title} <br />
                                                        </div>
                                                    })
                                                }
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label>Challenge file</label>
                                            <input type="file" className="form-control" name="source_file" onChange={handleFile} />
                                        </div>
                                        <div className="form-group">
                                            <label>Challenge picture</label>
                                            <input type="file" accept="image/*" className="form-control" name="picture" onChange={handleFile} />
                                        </div>
                                    <button type="Submit" className="btn btn-primary">Add Challenge</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const AddChallenge = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default AddChallenge;