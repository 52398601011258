
import './App.css';
import "./assets/css/style.min.css";
import "./assets/css/components.min.css";

import {Routes, Route, BrowserRouter} from "react-router-dom";


import Dashboard from "./pages/Profile/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import Modules from "./pages/Lecture/Lectures";
import Module from "./pages/Lecture/Module";
import Forum from "./pages/Thread/Forum";
import CreateThread from "./pages/Thread/CreateThread";
import Thread from './pages/Thread/ThreadDetails';
import Challenges from './pages/Challenge/Challenges';
import ChallengeDetails from './pages/Challenge/ChallengeDetails';
import Projects from './pages/Project/Projects';
import ProjectDetails from './pages/Project/ProjectDetails';
import ScheduleMeeting from './pages/ScheduleMeeting';
import Quizzes from './pages/Quiz/Quizes';
import MyProfile from './pages/Profile/MyProfile';
import Leaderboard from './pages/Profile/Leaderboard';
import CourseDetails from './pages/Course/CourseDetails';
import StudentProfile from './pages/Profile/StudentProfile';
import Login from './pages/Login';
import AddCourse from './pages/Course/AddCourse';
import AddLecture from './pages/Lecture/AddLecture';
import AddChallenge from './pages/Challenge/AddChallenge';
import Schedules from './pages/Schedule/Schedules';
import ScheduleDetails from './pages/Schedule/ScheduleDetails';
import AddProject from './pages/Project/AddProject';
import ProjectSubmissions from './pages/Project/ProjectSubmissions';
import ProjectSubmissionDetails from './pages/Project/SubmissionDetails';
import ChallengeSubmissions from './pages/Challenge/ChallengeSubmissions';
import ChallengeSubmissionDetails from './pages/Challenge/SubmissionDetails';
import LectureSubmissions from './pages/Lecture/LectureSubmissions';
import LectureSubmissionDetails from './pages/Lecture/SubmissionDetails';
import Courses from './pages/Course/Courses';
import StudentsList from './pages/Student/Student';
import EditCourse from './pages/Course/EditCourse';
import RegisterStudent from './pages/Student/AddStudent';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/login' element={<Login />} />

        <Route path='/add-lecture' element={<AddLecture />} />
        <Route path='/lectures' element={<Modules />} />
        <Route path='/lectures/:id' element={<Module />} />
        <Route path='/lecture-submissions' element={<LectureSubmissions />} />
        <Route path='/lecture-submissions/:id' element={<LectureSubmissionDetails />} />

        <Route path='/forum' element={<Forum />} />
        <Route path='/create-thread' element={<CreateThread />} />
        <Route path='/threads/:id' element={<Thread />} />

        <Route path='/add-challenge' element={<AddChallenge />} />
        <Route path='/challenges' element={<Challenges />} />
        <Route path='/challenges/:id' element={<ChallengeDetails />} />
        <Route path='/challenge-submissions' element={<ChallengeSubmissions />} />
        <Route path='/challenge-submissions/:id' element={<ChallengeSubmissionDetails />} />

        <Route path='/add-project' element={<AddProject />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/projects/:id' element={<ProjectDetails />} />
        <Route path='/project-submissions' element={<ProjectSubmissions />} />
        <Route path='/project-submissions/123' element={<ProjectSubmissionDetails />} />

        <Route path='/schedules' element={<Schedules />} />
        <Route path='/schedules/:id' element={<ScheduleDetails />} />
        <Route path='/schedule' element={<ScheduleMeeting />} />
        <Route path='/quizzes' element={<Quizzes />} />

        <Route path='/profile' element={<MyProfile />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/course-details' element={<CourseDetails />} />
        <Route path='/add-course' element={<AddCourse />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/edit-course/:id' element={<EditCourse />} />

        <Route path='/add-student' element={<RegisterStudent />} />
        <Route path='/students' element={<StudentsList />} />
        <Route path='/students/123' element={<StudentProfile />} />


        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
