
import { Link } from "react-router-dom";
import BodyWrapper from "../../components/layouts/BodyWrapper";


const Body = () => {
    return (
        <div className="main-content">
            
            <section className="section">
                <div className="section-header">
                    <h1>Dasboard</h1>
                    {/* <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item active"><a href="#">Dashboard</a></div>
                        <div className="breadcrumb-item">home</div>
                    </div> */}
                </div>

                <div className="section-body">
                    <h2 className="section-title">Hi, Welcome ARIB!</h2>
                    <p className="section-lead">What would you like to do now?</p>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-chalkboard-teacher"></i></div>
                                        <div className="card-body">
                                            <h4>Lecture Submissions</h4>
                                            <p style={{fontWeight: "600"}}>See new lecture submitted by students.</p>
                                            <Link to="/lecture-submissions" className="card-cta">See submissions <i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-laptop-code"></i></div>
                                        <div className="card-body">
                                            <h4>Challenge submissions</h4>
                                            <p>See challenges submitted by student.</p>
                                            <Link to="/challenge-submissions" className="card-cta">See submissions<i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-users"></i></div>
                                        <div className="card-body">
                                            <h4>Go to Forum</h4>
                                            <p>Join the discussion or ask a question and get help from co-learners.</p>
                                            <Link to="/forum" className="card-cta">Visit Forum<i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-shapes"></i></div>
                                        <div className="card-body">
                                            <h4>Technologies & Courses</h4>
                                            <p>See all technologie and courses and add new ones.</p>
                                            <Link to="/courses" className="card-cta">Courses<i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-users"></i></div>
                                        <div className="card-body">
                                            <h4>See Students</h4>
                                            <p>Overview all the students</p>
                                            <Link to="/students" className="card-cta">See students<i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const Dashboard = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default Dashboard;