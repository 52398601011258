import http from "axios";
import Cookies from "js-cookie";

// const serverurl = "http://localhost:4000";
const serverurl = "https://lmsserver.geegstack.academy";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("gs_admin");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                // "Content-Type": "application/x-www-formurlencoded",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;
const request = {
    get: (url, params) => http.get(`${serverurl}${url}`, { params }, requestOptions() ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    upload: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/x-www-formurlencoded")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, data, requestOptions("application/json")).then(response)
}

const General = {
    login: (params)=> request.post("/admin/login", params)
}

const Course = {
    addTechnology: (params)=> request.post("/courses/addTechnology", params),
    getTechnologies: (params)=> request.post("/courses/getTechnologies", params),
    addCourse: (params)=> request.post("/courses/addCourse", params),
    getCourses: (params)=> request.post("/courses/getCourses", params),
}

const Lecture = {
    addLecture: (params)=> request.post("/lectures/addLecture", params),
    introLectures: (params)=> request.post("/lectures/introLectures", params),
    getTechnologyLectures: (params)=> request.post("/lectures/getTechnologyLectures", params),
    lectureDetails: (params)=> request.post("/lectures/lectureDetails", params),
    getSubmittedLectures: (params)=> request.post("/lectures/getSubmittedLectures", params),
    getAllSubmissions: (params)=> request.post("/lectures/getAllSubmissions", params),
    getSubmissionDetails: (params)=> request.post("/lectures/getSubmissionDetails", params),
    gradeSubmission: (params)=> request.post("/lectures/gradeSubmission", params),
}

const Challenge = {
    addChallenge: (params)=> request.upload("/challenges/addChallenge", params),
    getALlChallenges: (params)=> request.post("/challenges/getALlChallenges", params),
    challengeDetails: (params)=> request.post("/challenges/challengeDetails", params),
}

const Project = {
    addProject: (params)=> request.upload("/projects/addProject", params),
    getAllProjects: (params)=> request.post("/projects/allProjects", params),
    projectDetails: (params)=> request.post("/projects/projectDetails", params),
}

const Student = {
    registerStudent: (params)=> request.post("/students/register", params),
    getAllStudents: (params)=> request.post("/students/allStudents", params),
    // projectDetails: (params)=> request.post("/projects/projectDetails", params),
}

export {General, Course, Lecture, Challenge, Project, Student, serverurl};