import Cookies from "js-cookie";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import geegstackLogo from "../assets/img/geegstack/logo-standalone.png";
import Toast from "../components/Toast";
import { General } from "../api";

const Login = () => {
    const admin = Cookies.get("gs_admin");
    const navigate = useNavigate();
    const [form, setForm] = useState({email: "", password: ""})

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        if(admin && admin.length > 1) {
            navigate("/");
        }
    })

    const Login = (e) => {
        e.preventDefault();
        if(!form.email || !form.password) {
            return Toast.fire({icon: "error", title: "Email and Password a re required"});
        }
        General.login(form).then((res) => {
            if(res.type == "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            console.log(res);
            Toast.fire({icon: "success", title :"Logged in"}).then(() => navigate("/"));
            Cookies.set("gs_admin", res.token);
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return (
        <div id="app">
            <section className="section">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                            <div className="login-brand">
                                <img src={geegstackLogo} alt="logo" width="100"/>
                            </div>
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h4>Login</h4>
                                </div>
                                <div className="card-body">
                                    <form method="POST" action="#" className="needs-validation" onSubmit={Login}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input id="email" type="email" className="form-control" name="email" tabIndex="1" required autoFocus onChange={inputChange} value={form.email} />
                                            <div className="invalid-feedback">
                                                Please fill in your email
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label">Password</label>
                                                <div className="float-right">
                                                    <a href="auth-forgot-password.html" className="text-small">
                                                    Forgot Password?
                                                    </a>
                                                </div>
                                            </div>
                                            <input id="password" type="password" className="form-control" name="password" tabIndex="2" required  onChange={inputChange} value={form.password} />
                                            <div className="invalid-feedback">
                                                please fill in your password
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                            Login
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="simple-footer">
                                Copyright &copy; Geegstack Academy 2022
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Login;