
import BodyWrapper from "../../components/layouts/BodyWrapper";
import avatar from "../../assets/img/avatar/avatar-1.png";
import { Link } from "react-router-dom";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Project Submissions</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Well done, ARIB!</h2>
                    <p class="section-lead">You're making progress. Keep it up!</p>
                    
                    
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Submissions</h4>
                                    <div class="card-header-form">
                                        <form>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Search"/>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table table-striped v_center">
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Project Title</th>
                                                <th>Project Technologies</th>
                                                <th>Submission Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            <tr>
                                                <td><Link to={"/students/123"}>Abdulrasheed Ibraheem</Link></td>
                                                <td class="align-middle">
                                                    NFT Card preview
                                                </td>
                                                <td>HTML, CSS</td>
                                                <td>2018-01-20</td>
                                                <td><div class="badge badge-success">Completed</div></td>
                                                <td><Link to="/project-submissions/123" class="btn btn-secondary">Detail</Link></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={"/students/123"}>Abdulrasheed Ibraheem</Link></td>
                                                <td class="align-middle">
                                                    NFT Card preview
                                                </td>
                                                <td>HTML, CSS</td>
                                                <td>2018-01-20</td>
                                                <td><div class="badge badge-success">Completed</div></td>
                                                <td><a href="/schedules/123" class="btn btn-secondary">Detail</a></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={"/students/123"}>Abdulrasheed Ibraheem</Link></td>
                                                <td class="align-middle">
                                                    NFT Card preview
                                                </td>
                                                <td>HTML, CSS</td>
                                                <td>2018-01-20</td>
                                                <td><div class="badge badge-success">Completed</div></td>
                                                <td><a href="/schedules/123" class="btn btn-secondary">Detail</a></td>
                                            </tr>
                                        
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const ProjectSubmissions = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default ProjectSubmissions;