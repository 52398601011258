

import BodyWrapper from "../../components/layouts/BodyWrapper";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatar/avatar-1.png";
import avatar2 from "../../assets/img/avatar/avatar-2.png";
import avatar3 from "../../assets/img/avatar/avatar-3.png";


const Body = () => {
    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Forum</h1>
                </div>
                <div className="section-body">
                    <h2 className="section-title">All Threads</h2>
                    <div className="row">
                    <div className="mt-4 mb-4 p-3 hide-sidebar-mini">
                        <Link to="/create-thread" className="btn btn-primary btn-lg btn-block">  <i className="fas fa-pencil-square"></i> &nbsp;  Create a thread</Link>
                    </div>
                        <div className="col-12">
                            <div className="activities">
                                <div className="activity">
                                    <div className="activity-icon "><img alt="image" src={avatar} className="rounded-circle mr-1" width={50} />
                                    </div>
                                    <div className="activity-detail">
                                        <div className="mb-2">
                                            <span className="text-job text-primary">2 min ago</span>
                                            <span className="bullet"></span>
                                            <Link className="text-job" to="/threads/123">View</Link>
                                        </div>
                                        <p className="font-weight-bold">How can I style a navbar with the class name yellow? Someone should please help.</p>
                                    </div>
                                </div>
                                <div className="activity">
                                    <div className="activity-icon "><img alt="image" src={avatar} className="rounded-circle mr-1" width={50} />
                                    </div>
                                    <div className="activity-detail">
                                        <div className="mb-2">
                                            <span className="text-job text-primary">1 hour ago</span>
                                            <span className="bullet"></span>
                                            <Link className="text-job" to="/threads/123">View</Link>
                                        </div>
                                        <p className="font-weight-bold">I ran to an error while trying to implement this plugin. Can anyone help check to see the problem.</p>
                                    </div>
                                </div>
                                <div className="activity">
                                    <div className="activity-icon "><img alt="image" src={avatar} className="rounded-circle mr-1" width={50} />
                                    </div>
                                    <div className="activity-detail">
                                        <div className="mb-2">
                                            <span className="text-job text-primary">Yesterday</span>
                                            <span className="bullet"></span>
                                            <Link className="text-job" to="/threads/123">View</Link>
                                        </div>
                                        <p className="font-weight-bold">How can I style a navbar with the class name yellow? Someone should please help.</p>
                                    </div>
                                </div>
                                <div className="activity">
                                    <div className="activity-icon "><img alt="image" src={avatar} className="rounded-circle mr-1" width={50} />
                                    </div>
                                    <div className="activity-detail">
                                        <div className="mb-2">
                                            <span className="text-job text-primary">yesterday</span>
                                            <span className="bullet"></span>
                                            <Link className="text-job" to="/threads/123">View</Link>
                                        </div>
                                        <p className="font-weight-bold">Guys, I heard there's a new version of React. How true is that?</p>
                                    </div>
                                </div>
                                <div className="activity">
                                    <div className="activity-icon "><img alt="image" src={avatar} className="rounded-circle mr-1" width={50} />
                                    </div>
                                    <div className="activity-detail">
                                        <div className="mb-2">
                                            <span className="text-job text-primary">yesterday</span>
                                            <span className="bullet"></span>
                                            <Link className="text-job" to="/threads/123">View</Link>
                                        </div>
                                        <p className="font-weight-bold">Guys, I heard there's a new version of React. How true is that?</p>
                                    </div>
                                </div>
                                <div className="activity">
                                    <div className="activity-icon bg-primary text-white shadow-primary"><i className="fas fa-trash"></i></div>
                                    <div className="activity-detail">
                                        <div className="mb-2">
                                            <span className="text-job">Yesterday</span>
                                            <span className="bullet"></span>
                                            <a className="text-job" href="#">View</a>
                                            <div className="float-right dropdown">
                                                <a href="#" data-toggle="dropdown"><i className="fas fa-ellipsis-h"></i></a>
                                                <div className="dropdown-menu">
                                                    <div className="dropdown-title">Options</div>
                                                    <a href="#" className="dropdown-item has-icon"><i className="fas fa-eye"></i> View</a>
                                                    <a href="#" className="dropdown-item has-icon"><i className="fas fa-list"></i> Detail</a>
                                                    <div className="dropdown-divider"></div>
                                                    <a href="#" className="dropdown-item has-icon text-danger" data-confirm="Wait, wait, wait...|This action can't be undone. Want to take risks?" data-confirm-text-yes="Yes, IDC"><i className="fas fa-trash-alt"></i> Archive</a>
                                                </div>
                                            </div>
                                        </div>
                                        <p>Assign the task of "<a href="#">Redesigning website header and make it responsive AF</a>" to <a href="#">Syahdan Ubaidilah</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const Forum = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default Forum;