
import BodyWrapper from "../../components/layouts/BodyWrapper";
import ModuleList from "../../components/ModuleLists";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Course, Lecture } from "../../api";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [lectures, setLectures] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [currentTechnology, setCurrentTechnology] = useState("Introduction");

    useEffect(() => {
        Lecture.introLectures().then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"))
            }
            setLectures(res.lectures);
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/"))
        })

        Course.getTechnologies().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            const techs = res.technologies.sort((a,b) => a.order - b.order )
            setTechnologies(techs);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
    }, [])

    const getTechnologyLectures = (e, id, name) => {
        e.preventDefault();
        Lecture.getTechnologyLectures({techID: id}).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            setLectures(res.lectures);
            setCurrentTechnology(name);
        }).catch(() => Toast.fire({icon: "error", title: "Network error"}))
    }

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header flex justify-content-between">
                    <h1>Lectures</h1>
                    <div>
                        <Link to={"/lecture-submissions"} className="btn btn-primary" id="save-btn">Lecture submissions</Link> &nbsp;
                        <Link to={"/add-lecture"} className="btn btn-primary" id="save-btn">Add New Lecture</Link>
                    </div>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Well done, ARIB!</h2>
                    <p className="section-lead">You're making progress. Keep it up!</p>

                    
                    <div className="row">
                        
                    <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Learning Path</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-2">
                                            <ul className="nav nav-pills flex-column" id="myTab4" role="tablist">
                                                {
                                                    technologies.length > 0 && technologies.map((tech, index) => {
                                                        return (
                                                            <li className="nav-item" key={tech.id}>
                                                                <a 
                                                                className={"nav-link " + (currentTechnology == tech.name ? "active" : "")} 
                                                                id={"intro-tab" + tech.id}
                                                                data-toggle="tab"
                                                                href={"#intro" + tech.id}
                                                                role="tab" aria-controls="intro" 
                                                                aria-selected="true"
                                                                onClick={ e => getTechnologyLectures(e, tech.id, tech.name)}
                                                                >
                                                                    <b>{tech.name}</b>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }

                                                {/* <li className="nav-item">
                                                    <a className="nav-link" id="home-tab4" data-toggle="tab" href="#home4" role="tab" aria-controls="home" aria-selected="true">
                                                        <b>HTML</b>
                                                    </a>
                                                </li> */}
                                                
                                            </ul>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-10">
                                            <div className="tab-content no-padding" id="myTab2Content">

                                                <div className="tab-pane fade show active" id="intro12" role="tabpanel" aria-labelledby="intro-tab12">
                                                    <ModuleList course={currentTechnology} lectures={lectures} />    
                                                </div>

                                                {/* <div className="tab-pane fade" id="profile4" role="tabpanel" aria-labelledby="profile-tab4">
                                                    {/* <ModuleList /> */}
                                                {/* </div> */}

                                                {/* <div className="tab-pane fade" id="contact4" role="tabpanel" aria-labelledby="contact-tab4"> 
                                                    {/* <ModuleList /> 
                                                </div> */}
                                            </div>
                                        </div>
                                    
                                    </div>
                                
                                
                                </div>
                    
                        </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

const Modules = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default Modules;