

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Challenge, Course, Lecture, Student } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({fname: "", lname: "", email: "", course:"Frontend Development", dateEnrolled: "", learningMode: ""});
    const [courses, setCourses] = useState([]);
    

    //Input change handler
    const inputChange = (e) => {
        return setForm({...form, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        Course.getCourses().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setCourses(res.courses);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })

    }, [])

    
    const submitForm = e => {
        e.preventDefault();

        Student.registerStudent(form).then(res => {
            console.log(res);
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "New Student added successfully!"}).then(() => navigate("/students"))
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }


    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Register Student</h1>
                </div>

                <div className="section-body">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-7">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Add New Student</h4>
                                </div>
                                <form action="" onSubmit={submitForm} method="POST">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control" onChange={inputChange} name="fname" value={form.fname} />
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" onChange={inputChange} name="lname" value={form.lname} />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" onChange={inputChange} name="email" value={form.email} />
                                        </div>
                                        <div className="form-group">
                                            <label>Course</label>
                                            <select className="form-control select2" onChange={inputChange} name="course" value={form.course}>
                                                {
                                                    courses.map(course => {
                                                        return <option value={course.title} key={course.id}>{course.title}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Learning Mode</label>
                                            <select className="form-control select2" onChange={inputChange} name="learningMode" value={form.learningMode}>
                                                <option value="Online">Online</option>
                                                <option value="Physical">Physical</option>
                                                <option value="Accelerated Learning">Accelerated Learning</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Enrollment Date</label>
                                            <input type="date" className="form-control" onChange={inputChange} name="dateEnrolled" value={form.dateEnrolled} />
                                        </div>
                                    <button type="Submit" className="btn btn-primary">Add Student</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const RegisterStudent = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default RegisterStudent;