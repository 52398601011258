import TopNavbar from "./TopNavbar";
import Sidebar from "./Sidebar";

const BodyWrapper = ({children}) => {
    return ( 
        <div id="app">
        {/* <!-- Page Loader --> */}
        {/* <div className="page-loader-wrapper">
            <span className="loader"><span className="loader-inner"></span></span>
        </div> */}
            <div className="main-wrapper main-wrapper-1">
                <div className="navbar-bg"></div>
                <TopNavbar />
                <Sidebar />
                {children}
                {/* <!-- Start app Footer part --> */}
                <footer className="main-footer">
                    <div className="footer-left">
                        <div className="bullet"></div>  <a href="https://geegstack.academy">Geegstack Academy</a>
                    </div>
                    <div className="footer-right">
                    
                    </div>
                </footer>
            </div>
        </div>
    )
}
export default BodyWrapper;