
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Challenge } from "../../api";
import threadImg from "../../assets/img/news/img12.jpg";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [challenge, setChallenge] = useState({});

    const challengeID = useLocation().pathname.split("/")[2];

    useEffect(() => {
        Challenge.challengeDetails({challengeID}).then(res => {
            if(res.type === "error") {
                return Toast.fire({title: res.message, icon: "error"}).then(() => navigate("/challenges"));
            }
            setChallenge(res.challenge);
        }).catch(err => Toast.fire({title: "Network error", icon: "error"}).then(() => navigate("/challenges")))
    }, [])

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>{challenge.title}</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Are you ready?</h2>
                    <p className="section-lead">Let's get in!</p>

                    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Start a new Challenge</h4>
                                </div>
                                <div className="row card-body">
                                    <div className="col-12 col-sm-6 col-md-6">
                                        <img src={"http://localhost:4000" + challenge.picture_url} width="100%" alt="" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6">
                                        <h4>Challenge Description</h4>
                                        <p>{challenge.description}</p>
                                        <div className="card-footer text-md-right">
                                            <a href={"http://localhost:4000" + challenge.file_url} download={"http://localhost:4000/" + challenge.file_url} className="btn btn-primary" id="save-btn"> <i className="fas fa-download"></i> Download starter file</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Challenge Submission</h4>
                                </div>
                                <div className="card-body">
                                    <h6>Upload Your Solution file:</h6>
                                    <form action="">
                                        <div className="form-group">
                                            <input type="file" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="comment"><h6>Do you have any comment?</h6></label>
                                            <textarea id="comment"  className="form-control" placeholder="Enter your comments here"> </textarea>
                                        </div>
                                        <div className="card-footer text-md-right">
                                            <button className="btn btn-primary" id="save-btn"> <i className="fas fa-upload"></i> Submit Solution</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const ChallengeDetails = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default ChallengeDetails;