

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Course } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";


const Body = () => {
    const navigate= useNavigate();
    const [technologies, setTechnologies] = useState([]);
    const [courseForm, setCourseForm] = useState({title: "", description: "", technologies: []});
    const [techForm, setTechForm] = useState({name: "", description: ""});

    //Technolgy handler
    const techInputChange = (e) => {
        setTechForm({...techForm, [e.target.name]: e.target.value});
    }
    const AddTechnology = e => {
        e.preventDefault();
        Course.addTechnology(techForm).then(res => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Technology added SUccessfully!"});
            setTechForm({name: "", description: ""})
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }


    //Course handler
    const courseInputChange = (e) => {
        if(e.target.name !== "technologies") {
            return setCourseForm({...courseForm, [e.target.name]: e.target.value});
        }
        if(courseForm.technologies.indexOf(e.target.value) > -1) {
            const techs = courseForm.technologies.filter(tech => tech !== e.target.value);
            setCourseForm({...courseForm, technologies: techs});
        } else {
            courseForm.technologies.push(e.target.value);
            setCourseForm({...courseForm, technologies: courseForm.technologies});
        }
    }
    const AddCourse = e => {
        e.preventDefault()
        Course.addCourse(courseForm).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Course added SUccessfully!"}).then(() => navigate("/courses"));
        }).catch(er => {
            console.log(er);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    useEffect(() => {
        Course.getTechnologies().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setTechnologies(res.technologies);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
    })

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Add Course</h1>
                </div>

                <div className="section-body">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Add New Course</h4>
                                    </div>
                                    <form action="" onSubmit={AddCourse}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Course Title</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="title" 
                                                    value={courseForm.title}
                                                    onChange={courseInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Course Description</label>
                                                <div className="input-group">
                                                    <textarea 
                                                    className="form-control"
                                                    name="description"
                                                    id="" cols="30" rows="10"
                                                    value={courseForm.description}
                                                    onChange={courseInputChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Course Technologies</label>
                                                <div className="input-groups">
                                                    {
                                                        (technologies && technologies.length > 0) && technologies.map(tech => {
                                                            return <div key={tech.id}>
                                                            <input 
                                                            type="checkbox" 
                                                            value={tech.name} 
                                                            name="technologies" 
                                                            onChange={courseInputChange}
                                                            /> 
                                                            &nbsp;{tech.name} <br />
                                                            </div>
                                                        })
                                                    }
                                                    
                                                    {/* <input type="checkbox" /> &nbsp;CSS <br />
                                                    <input type="checkbox" /> &nbsp;JavaScript <br /> */}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Add Course</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Add New Technology</h4>
                                    </div>
                                    <form action="" onSubmit={AddTechnology}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Technology Title</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="name" 
                                                    value={techForm.name} 
                                                    onChange={techInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Technology Description</label>
                                                <div className="input-group">
                                                    <textarea 
                                                    className="form-control" 
                                                    name="description" 
                                                    id="" cols="30" rows="10"
                                                    value={techForm.description}
                                                    onChange={techInputChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Add Technology</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const AddCourse = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default AddCourse;