

import BodyWrapper from "../../components/layouts/BodyWrapper";
import avatar from "../../assets/img/avatar/avatar-1.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Student, serverurl } from "../../api";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);

    useEffect(() => {
        Student.getAllStudents().then(res => {
            if(res.type === "error") {
                return Toast.fire({title: res.message, icon: "error"}).then(() => navigate("/"));
            }
            setStudents(res.students);
            console.log(res);
        }).catch((err) => {
            console.log(err);
            Toast.fire({title: "Network error", icon: "error"}).then(() => navigate("/"))
        })
    }, [])

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header d-flex justify-content-between">
                    <h1>Students</h1>
                    <div>    
                        <Link to={"/add-student"} className="btn btn-primary" id="save-btn">Add New Student</Link>
                    </div>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Student's List</h2>
                    <p className="section-lead">See how you and your mates are doing.</p>

                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <h4>Learners</h4>
                                </div>
                                <div className="card-body">
                                    <ul className="list-unstyled user-details list-unstyled-border list-unstyled-noborder">
                                        {
                                            students && students.map(student => {
                                                return (
                                                <li className="media" key={student.id}>
                                                    <img alt="image" className="mr-3 rounded-circle" height={"50"} width="50" src={student.avatar_url ? serverurl + "/profile_pics/" + student.avatar_url : avatar} style={{objectFit: "cover"}} />
                                                    <div className="media-body">
                                                        <div className="media-title"><Link to={"/students/" + student.id}>{student.fname} {student.lname}</Link></div>
                                                        <div className="text-job text-muted">{student.course} Student</div>
                                                    </div>
                                                    <div className="media-items">
                                                        <div className="media-item">
                                                            <div className="media-value">{student.projects}</div>
                                                            <div className="media-label">Projects</div>
                                                        </div>
                                                        <div className="media-item">
                                                            <div className="media-value">{student.challenges}</div>
                                                            <div className="media-label">Challenges</div>
                                                        </div>
                                                        <div className="media-item">
                                                            <div className="media-value">{student.stars}</div>
                                                            <div className="media-label">Stars</div>
                                                        </div>
                                                        <div className="media-item">
                                                            {/* <div className="media-values">09/05/2022 12:00:09 PM</div> */}
                                                            <div className="media-values">{student.last_activity_date && new Date(student.last_activity_date)}</div>
                                                            <div className="media-label">Last login</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                )
                                            })
                                        }
                                        {/* <li className="media">
                                            <img alt="image" className="mr-3 rounded-circle" width="50" src={avatar} />
                                            <div className="media-body">
                                                <div className="media-title">Olayiwola Abdulrahman</div>
                                                <div className="text-job text-muted">Frontend Student</div>
                                            </div>
                                            <div className="media-items">
                                                <div className="media-item">
                                                    <div className="media-value">9</div>
                                                    <div className="media-label">Projects</div>
                                                </div>
                                                <div className="media-item">
                                                    <div className="media-value">21</div>
                                                    <div className="media-label">Challenges</div>
                                                </div>
                                                <div className="media-item">
                                                    <div className="media-value">1,010</div>
                                                    <div className="media-label">Stars</div>
                                                </div>
                                                <div className="media-item">
                                                    <div className="media-values">09/05/2022 12:00:09 PM</div>
                                                    <div className="media-label">Last login</div>
                                                </div>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const StudentsList = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default StudentsList;