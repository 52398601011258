
import BodyWrapper from "../../components/layouts/BodyWrapper";
import avatar from "../../assets/img/avatar/avatar-1.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Lecture } from "../../api";
import Toast from "../../components/Toast";

const Body = () => {

    const navigate = useNavigate();
    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        Lecture.getAllSubmissions()
        .then(res => {
            if (res.type === "error") {
               return Toast.fire({icon: "error", title : res.message}).then(() => navigate("/"));
            }
            setSubmissions(res.submissions);
        })
        .catch(err => {
            console.log(err);
        })
    }, [])

    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Lecture Submissions</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Well done, ARIB!</h2>
                    <p class="section-lead">You're making progress. Keep it up!</p>
                    
                    
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Submissions</h4>
                                    <div class="card-header-form">
                                        <form>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Search"/>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table table-striped v_center">
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Lecture Title</th>
                                                <th>Submission Date</th>
                                                <th>Last Review</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            {
                                                submissions && submissions.length > 1 ?
                                                submissions.map((submission, id) => {
                                                    return (
                                                        <tr key={id}>
                                                        <td><Link to={"/students/123"}>{submission.fname} {submission.lname}</Link></td>
                                                        <td class="align-middle">
                                                            {submission.title}
                                                        </td>
                                                        <td>{new Date(submission.date_submitted).toDateString()} / {new Date(submission.date_submitted).toLocaleTimeString()}</td>
                                                        <td>{submission.status !== "Pending" ? new Date(submission.date_submitted).toDateString() + "/" + new Date(submission.date_submitted).toLocaleTimeString() : "Not reviewed"}</td>

                                                        <td><div class={submission.status === "Pending" ? "badge badge-warning" : submission.status === "Accepted" ? "badge badge-success" : "badge badge-danger"}>{submission.status}</div></td>
                                                        <td><Link to={"/lecture-submissions/" + submission.id} class="btn btn-secondary" target="_blank">Detail</Link></td>
                                                    </tr>
                                                    )
                                                })
                                            
                                            :
                                            <p>No submission yet.</p>
                                            }
                                        
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const LectureSubmissions = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default LectureSubmissions;