

import BodyWrapper from "../../components/layouts/BodyWrapper";
import threadImg from "../../assets/img/news/img12.jpg";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatar/avatar-1.png";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Thread</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title"><Link to={"/users/akande"}>By Abdulrasheed Ibraheem</Link></h2>
                    <p class="section-lead">Friday, January 13, 2022</p>

                    
                    <div class="row">
                        <div class="col-12">
                            <div class="col-md-10">
                                <div class="card" id="settings-card">
                                    <div class="card-header">
                                        <h3>How to make a lot of money</h3>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group text-center">
                                            <img src={threadImg} width="100%" style={{maxWidth: "1000px"}} alt="" />
                                        </div>

                                        <br />
                                        <div class="form-group row ">
                                            {/* <h6 for="site-description" class="form-control-p col-sm-3 text-md-right">Post:</h6> */}
                                            <div class="col-sm-6 col-md-9">
                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum facilis, delectus repellendus cumque dolores harum similique laboriosam fugit voluptate dolore quidem voluptates, et hic facere consequuntur qui nobis libero id.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                <h4>Contributions</h4>
                                </div>
                                <div class="card-body">
                                    <ul class="list-unstyled list-unstyled-border list-unstyled-noborder">
                                        <li class="media">
                                            <img alt="image" class="mr-3 rounded-circle" width="60" src={avatar}/>
                                            <div class="media-body">
                                                <div class="media-title mb-1">Debra Stewart</div>
                                                <div class="text-time">Yesterday</div>
                                                <div class="media-description text-muted">Duis aute irure dolor in reprehenderit in voluptate velit esse
                                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                            </div>
                                        </li>
                                        <li class="media">
                                            <img alt="image" class="mr-3 rounded-circle" width="60" src={avatar} />
                                            <div class="media-body">
                                                <div class="media-title mb-1">Erin Gonzales</div>
                                                <div class="text-time">Yesterday</div>
                                                <div class="media-description text-muted">Duis aute irure dolor in reprehenderit in voluptate velit esse
                                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                                            </div>
                                        </li>
                                        <li class="media">
                                            <img alt="image" class="mr-3 rounded-circle" width="60" src={avatar} />
                                            <div class="media-body">
                                                <div class="media-title mb-1">Michelle Green</div>
                                                <div class="text-time">Yesterday</div>
                                                <div class="media-description text-muted">Duis aute irure dolor in reprehenderit in voluptate velit esse
                                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                                proident</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Contribute</h4>
                                        </div>
                                        <div className="card-body">
                                            <div class="form-group">
                                                <textarea class="form-control" cols={150} rows={70} placeholder="State your opinion here" ></textarea>
                                            </div>
                                            <div class="card-footer text-md-right">
                                                <button class="btn btn-primary">Post Opinion</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const Thread = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default Thread;