
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Course, Project } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({title: "", description: "", level: 1, technologies: [], stacks: []});
    const [files, setFiles] = useState({source_file: null, picture: null});
    const [technologies, setTechnologies] = useState([]);
    const [courses, setCourses] = useState([]);
        
    //Input change handler
    const inputChange = (e) => {
        
        if (e.target.name !== "stacks" && e.target.name !== "technologies") {
            return setForm({...form, [e.target.name]: e.target.value});
        }

        //For Checkboxes
        if(form[e.target.name].indexOf(e.target.value) > -1) {
            const values = form[e.target.name].filter(tech => tech !== e.target.value);
            setForm({...form,[e.target.name]: values});
        } else {
            const prevValue = form[e.target.name];
            prevValue.push(e.target.value);
            setForm({...form,[e.target.name]: prevValue});
        }

    }
    
    //File change handler
    const handleFile = e => {
        setFiles({...files, [e.target.name]: e.target.files[0]});
    }
    useEffect(() => {
        Course.getTechnologies().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setTechnologies(res.technologies);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
        Course.getCourses().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setCourses(res.courses);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
    }, [])

    
    const submitProjectForm = e => {
        e.preventDefault();
        const projectForm = new FormData();
        for (const [key, value] of Object.entries(form)) {
            projectForm.append(key, value);
        }

        projectForm.append("picture", files["picture"]);
        projectForm.append("source_file", files["source_file"]);

        console.log(files["picture"])

        Project.addProject(projectForm).then(res => {
            console.log(res);
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Project added successfully!"}).then(() => navigate("/projects"))
        }).catch((err) => {
            console.log(err);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Add Project</h1>
                </div>

                <div class="section-body">
                    <div class="row">
                        <div class="col-12 col-md-7 col-lg-7">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Add new Project</h4>
                                </div>
                                <form action="" onSubmit={submitProjectForm}>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Project Title</label>
                                            <input type="text" class="form-control" name="title" onChange={inputChange} value={form.title}  />
                                        </div>
                                        <div class="form-group">
                                            <label>Project Description</label>
                                            <textarea class="form-control" name="description" onChange={inputChange} value={form.description}></textarea>
                                        </div>
                                        <div class="form-group">
                                            <label>Level</label>
                                            <select class="form-control select2" name="level" onChange={inputChange} value={form.level}>
                                                <option value={1}>Level 1</option>
                                                <option value={2}>Level 2</option>
                                                <option value={3}>Level 3</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Technologies</label>
                                            <div className="d-flex flex-wrap justify-content-between">
                                            {
                                                technologies.length > 0 && technologies.map(tech => {
                                                    return <div key={tech.id}>
                                                        <input 
                                                        type="checkbox" 
                                                        value={tech.name} 
                                                        name="technologies" 
                                                        onChange={inputChange}
                                                        id={tech.name}
                                                    /> <label htmlFor={tech.name}>{tech.name}</label>
                                                    </div>
                                                })
                                            }
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Stacks</label>
                                            <div>
                                                {
                                                    courses.length > 0 && courses.map(course => {
                                                        return <div key={course.id}>
                                                            <input 
                                                    type="checkbox" 
                                                    value={course.title} 
                                                    id={course.title} 
                                                    name="stacks" 
                                                    onChange={inputChange}
                                                /> <label htmlFor={course.title}>{course.title}</label> <br />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Project file</label>
                                            <input type="file" class="form-control" onChange={handleFile} name="source_file" />
                                        </div>
                                        <div class="form-group">
                                            <label>Project picture</label>
                                            <input type="file" accept="image/*" class="form-control" onChange={handleFile} name="picture" />
                                        </div>
                                        <button type="Submit" className="btn btn-primary">Add Project</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const AddProject = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default AddProject;