

import BodyWrapper from "../../components/layouts/BodyWrapper";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import avatar from "../../assets/img/avatar/avatar-1.png";

const Body = () => {
    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>My Profile</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Hi, Ibraheem!</h2>
                    <p className="section-lead">You can edit your account from here</p>

                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-5">
                            <div className="card profile-widget">
                                <div className="profile-widget-header">                     
                                    <img alt="image" src={avatar} className="rounded-circle profile-widget-picture" />
                                    <div className="profile-widget-items">
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Challenges</div>
                                            <div className="profile-widget-item-value">187</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Projects</div>
                                            <div className="profile-widget-item-value">6</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Stars</div>
                                            <div className="profile-widget-item-value">225</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name">Abdulrasheed Ibraheem <div className="text-muted d-inline font-weight-normal"><div className="slash"></div>Frontend Student</div></div>
                                    Abdulrasheed Ibraheem is a superhero name in <b>USA</b> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident, harum. Beatae veritatis quasi aspernatur obcaecati voluptatum neque eaque tempore. Assumenda vero neque dicta alias quidem quisquam necessitatibus, delectus voluptates ab.<b>'John Doe'</b>.
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-7">
                            <div className="card">
                                <form method="post" className="needs-validation" novalidate="">
                                    <div className="card-header">
                                        <h4>Edit Profile</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-6 col-12">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" value="Michelle" required="" />
                                                <div className="invalid-feedback">Please fill in the first name</div>
                                            </div>
                                            <div className="form-group col-md-6 col-12">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" value="Green" required="" />
                                                <div className="invalid-feedback">Please fill in the last name</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-7 col-12">
                                                <label>Email</label>
                                                <input type="email" className="form-control" value="Michelle@Green.com" required="" />
                                                <div className="invalid-feedback">Please fill in the email</div>
                                            </div>
                                            <div className="form-group col-md-5 col-12">
                                                <label>Phone</label>
                                                <input type="tel" className="form-control" value="" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label>Bio</label>
                                                <ReactQuill></ReactQuill>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary">Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const MyProfile = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default MyProfile;