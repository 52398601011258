import { Link } from "react-router-dom";

const numToStr = (num) => {
    return num < 10 ? "0" + num : num;
}

const ModuleList = ({lectures, course}) => {

    return (
    <div className="card">
        <div className="card-header">
            <h4>{course} Modules. Total: {lectures.length}</h4>
            <div className="card-header-action">
                
            </div>
        </div>
        <div className="card-body p-0">
            <div className="table-responsive">
                <table className="table table-striped v_center" id="sortable-table">
                    <thead>
                        <tr>
                        <th>Description</th>
                        <th>Duration</th>
                        <th>Completions</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lectures.length > 0 ? lectures.map(lecture => {
                                return (
                                    <tr key={lecture.id}>
                                        <td>{lecture.title}</td>
                                        <td>{numToStr(Math.floor(lecture.duration/3600))}:{numToStr(Math.floor(lecture.duration/60))}:{numToStr(lecture.duration%60)}</td>
                                        <td><div className="badge badge-success"></div></td>
                                        <td><Link to={"/lectures/" + lecture.id} className="btn btn-primary btn-sm">Watch</Link> &nbsp;
                                        <Link to={"/edit-lecture/" + lecture.id} className="btn btn-warning btn-sm">Edit</Link>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <p>No Lecture has been added for {course} yet.</p>
                        }

                        {/* <tr>
                            <td>Backup database</td>
                            <td>00:23:02</td>
                            <td><div className="badge badge-warning">In Progress</div></td>
                            <td><Link to="/lectures/123" className="btn btn-primary"><span className="fas fa-play"></span> &nbsp; Watch</Link></td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export default ModuleList;