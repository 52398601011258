

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Course, Lecture } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";


const Body = () => {
    const navigate = useNavigate();
    const [technologies, setTechnologies] = useState([]);
    const [form, setForm] = useState({title: "", link: "", notes:"", order: 0, duration: 0, techID: "1"})

    const inputChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        Course.getTechnologies().then((res) => {
            if(res.type === "error"){
                return Toast.fire({icon: "error", title: "Error fetching technologies"}).then(() => navigate("/") );
            }
            setTechnologies(res.technologies);
        }).catch(err => {
            return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/") );
        })
    })

    const addLecture = e => {
        e.preventDefault();
        Lecture.addLecture(form).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Course added successfully!"}).then(() => navigate("/lectures"));
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error"});
        });
    }

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Add Lecture</h1>
                </div>

                <div className="section-body">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-7">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Add new lecture</h4>
                                </div>
                                <form action="" onSubmit={addLecture}>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Lecture Title</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            name="title"
                                            value={form.title}
                                            onChange={inputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Lecture Link</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            name="link"
                                            value={form.link}
                                            onChange={inputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Lecture Notes</label>
                                            <textarea 
                                            className="form-control"
                                            name="notes"
                                            value={form.notes}
                                            onChange={inputChange}
                                            ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <label>Lecture Order</label>
                                            <input 
                                            type="number" 
                                            className="form-control"
                                            name="order"
                                            value={form.order}
                                            onChange={inputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Lecture Duration in secs</label>
                                            <input 
                                            type="number" 
                                            className="form-control"
                                            name="duration"
                                            value={form.duration}
                                            onChange={inputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Technology</label>
                                            <select className="form-control" name="techID" value={form.techID} onChange={inputChange}>
                                                {
                                                    technologies.length > 0 && technologies.map(tech => {
                                                        return <option key={tech.id} value={tech.id}>{tech.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    <button type="Submit" className="btn btn-primary">Add Lecture</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const AddLecture = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default AddLecture;