
import { Link, useNavigate } from "react-router-dom";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import threadImg from "../../assets/img/news/img12.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Project } from "../../api";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        Project.getAllProjects().then(res => {
            if(res.type==="error") {
                return Toast.fire({title: res.message, icon: "error"}).then(() => navigate("/"));
            }
            setProjects(res.projects);
        }).catch(() => Toast.fire({title: "Network error", icon: "error"}).then(() => navigate("/")))
    }, [])

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header flex justify-content-between">
                    <h1>Projects</h1>
                    
                    <Link to={"/project-submissions"} className="btn btn-primary" id="save-btn">Project Submissions</Link>
                    &nbsp;
                    <Link to={"/add-project"} className="btn btn-primary" id="save-btn">Add New Project</Link>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Are you ready?</h2>
                    <p className="section-lead">Remember the only way to be a developer is by developing!</p>

                    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <ul className="nav nav-pills" id="myTab3" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab3" data-toggle="tab" href="#home3" role="tab" aria-controls="profile" aria-selected="false"><b>HTML</b></a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected="false"><b>CSS</b></a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected="false"><b>JavaScript</b></a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected="false"><b>React</b></a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected="false"><b>Full stack</b></a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab3" data-toggle="tab" href="#profile3" role="tab" aria-controls="home" aria-selected="true"><b>All</b></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body bg-light">
                                    <div className="tab-content" id="myTabContent2">
                                        <div className="tab-pane fade show active" id="home3" role="tabpanel" aria-labelledby="home-tab3">
                                            <div className="row">
                                                {
                                                    projects && projects.length > 0 && projects.map(project => {
                                                        return (
                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3" key={project.id}>
                                                            <article className="article article-style-b">
                                                                <div className="article-header">
                                                                    <div className="article-image" data-background={threadImg}>
                                                                        <img src={project.picture_url && "http://localhost:4000" + project.picture_url} width="100%" alt="" />
                                                                    </div>
                                                                    <div className="article-badge">
                                                                        <span className="badge badge-danger font-weight-bold">{project.technologies}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="article-details">
                                                                    <div className="article-title">
                                                                        <h6>{project.title}</h6>
                                                                    </div>
                                                                    {/* <p>You need to complete this project to proceed to the next technology.</p> */}
                                                                    <div className="article-cta">
                                                                        <Link to={"/projects/" + project.id}>Start Project<i className="fas fa-chevron-right"></i></Link>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                        )
                                                    })
                                                }
                                                
                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                                    <article className="article article-style-b">
                                                        <div className="article-header">
                                                            <div className="article-image" data-background={threadImg}>
                                                                <img src={threadImg} width="100%" alt="" />
                                                            </div>
                                                            <div className="article-badge">
                                                                <span className="badge badge-secondary font-weight-bold">CSS</span>
                                                            </div>
                                                        </div>
                                                        <div className="article-details">
                                                            <div className="article-title">
                                                                <h6>Excepteur sint occaecat cupidatat non proident</h6>
                                                            </div>
                                                            <br />
                                                            <div className="article-cta">
                                                                <a href="#">View Challenge <i className="fas fa-chevron-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab3">
                                        Sed sed metus vel lacus hendrerit tempus. Sed efficitur velit tortor, ac efficitur est lobortis quis. Nullam lacinia metus erat, sed fermentum justo rutrum ultrices. Proin quis iaculis tellus. Etiam ac vehicula eros, pharetra consectetur dui.
                                        </div>
                                        <div className="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab3">
                                        Vestibulum imperdiet odio sed neque ultricies, ut dapibus mi maximus. Proin ligula massa, gravida in lacinia efficitur, hendrerit eget mauris. Pellentesque fermentum, sem interdum molestie finibus, nulla diam varius leo, nec varius lectus elit id dolor.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const Projects = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default Projects;