

import BodyWrapper from "../../components/layouts/BodyWrapper";
import { Link } from "react-router-dom";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Edit Course</h1>
                </div>

                <div class="section-body">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4>Edit Course</h4>
                                    </div>
                                    <form action="">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Course Title</label>
                                                <input type="text" class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label>Course Description</label>
                                                <div class="input-group">
                                                    <textarea className="form-control" name="" id="" cols="30" rows="10"></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Course Technologies</label>
                                                <div class="input-groups">
                                                    <input type="checkbox" /> &nbsp;HTML <br />
                                                    <input type="checkbox" /> &nbsp;CSS <br />
                                                    <input type="checkbox" /> &nbsp;JavaScript <br />
                                                    <input type="checkbox" /> &nbsp;React <br />
                                                    <input type="checkbox" /> &nbsp;Jquery <br />
                                                    <input type="checkbox" /> &nbsp;Bootstrap <br />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Edit Course</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4>Edit Technology</h4>
                                    </div>
                                    <form action="">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Technology Title</label>
                                                <input type="text" class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label>Technology Description</label>
                                                <div class="input-group">
                                                    <textarea className="form-control" name="" id="" cols="30" rows="10"></textarea>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Edit Technology</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const EditCourse = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default EditCourse;